import toast from "react-hot-toast";
import Spinner from "../Loading/Spinner";
import { useSelector } from "react-redux";
import dropin from "braintree-web-drop-in";
import React, { useState, useEffect } from "react";
import { ApiURL } from "../../setting/GlobleVariable";

const CreditCardPayment = ({
  show,
  onPaymentCompleted,
  totalamount,
  setShow,
  createTevoClient
}) => {
  const isloadingcreateOrder = useSelector((state) => state.events.isLoading);
  const isloadingUserOrder = useSelector((state) => state.order.isloading)
  const [braintreeInstance, setBraintreeInstance] = useState(null);
  const user = useSelector((state) => state.user.user);
  const [loading, setloading] = useState(false);
  const [payment, setPayment] = useState(false);

  const singleCouponCode = useSelector(
    (state) => state.couponcode.singleCouponCode
  );

  useEffect(() => {
    const initializeBraintree = async () => {
      if (show === true) {
        try {
          setloading(true);
          if (!user?.clientId) {
            var clientId;
            clientId = sessionStorage.getItem("client_id");
            if (!clientId) {
             clientId = await createTevoClient();
            }
          }
          const response = await fetch(`${ApiURL}/payment/generateClient/${user?.clientId || clientId}`, {
            method: "GET",
            headers: {
              token: localStorage.getItem("instapass_user_token"),
            },
          });
          const data = await response.json();

          const { clientToken } = data;
          if (response.status === 400 || response.status === 500) {
            setShow(0)
            return toast.error(data?.message || "Something went wrong");
          }

          var instance = await dropin.create({
            authorization: clientToken,
            container: "#braintree-drop-in-div",
            card: {
              flow: "vault",
              amount:
                singleCouponCode && singleCouponCode?.discount
                  ? totalamount - singleCouponCode?.discount <= 0
                    ? 0
                    : totalamount - singleCouponCode?.discount
                  : totalamount,
              currency: "USD",
            },
          });
          setBraintreeInstance(instance);

          instance.on("paymentMethodRequestable", async (event) => {
            try {
              // Use the event object to get the paymentMethodNonce
              const { nonce } =
                await event.currentTarget.requestPaymentMethod();
            } catch (error) {
              console.error("Error handling payment:", error);
              // toast.error(error.message || "Something went wrong")

            }
          });
          setloading(false);
        } catch (error) {
          console.error("Error initializing Braintree:", error);
          toast.error(error.message || "Something went wrong")

        }
      }
    };

    if (show === true) {
      initializeBraintree();
    } else {
      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => setBraintreeInstance(null));
      }
    }

    return () => {
      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => setBraintreeInstance(null));
      }
    };
  }, [show]);

  return (
    <div style={{ display: `${show ? "block" : "none"}` }}>
      <div id="braintree-drop-in-div" />
      {loading ? (
        <div className="w-full flex justify-center place-items-center">
          <Spinner />
        </div>
      ) : payment ? null : (
        <button
          className="bg-[black] text-[white] px-6 py-2 cursor-pointer"
          type="primary"
          disabled={!braintreeInstance || loading || isloadingcreateOrder || isloadingUserOrder}
          onClick={async () => {
            if (braintreeInstance) {
              braintreeInstance.requestPaymentMethod(async (error, payload) => {
                if (error) {
                  console.error(error);
                } else {
                  const paymentMethodNonce = payload.nonce;
                  setPayment(true);
                  onPaymentCompleted({
                    payment_method_nonce: paymentMethodNonce,
                    paymentType: "credit_card",
                    paymentMethod: 'BraintreeCard'
                  });
                }
              });
            }
          }}
        >
          {!loading && "Pay"}
        </button>
      )}
    </div>
  );
};

export default CreditCardPayment;

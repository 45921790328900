import React from 'react'
import { RxCross2 } from 'react-icons/rx'
import Spinner from '../../Loading/Spinner'

const Coupon = ({coupon , setcoupon,  handleApplyCoupon , handleRemoveCoupon , CouponLoading}) => {
    return (
        <div>
            <label
                for="voucher"
                className="mb-2 block text-sm font-medium text-[white] dark:text-white"
            >
                Enter a promotional code{" "}
            </label>
            <div className="max-w-[100%] flex gap-2 mb-5 !text-[white]">
                <div className="w-[100%] relative">
                    <input
                        type="text"
                        placeholder="enter promo code"
                        className="w-[100%] py-2 px-3 rounded-md outline-none border-none bg-black"
                        style={{
                            boxShadow: "0px 0px 5px 2px #cd292e85",
                        }}
                        value={coupon}
                        onChange={(e) => setcoupon(e.target.value)}
                    />
                    <RxCross2 className="absolute right-3 top-[10px] z-10 cursor-pointer text-[22px]"
                        onClick={handleRemoveCoupon}
                    />
                </div>
                {CouponLoading ?
                    <button className="w-[150px] bg-[#ac332580] rounded-md text-[white] py-1 px-4 cursor-not-allowed">
                        <Spinner />
                    </button>

                    :
                    <button className="w-[150px] bg-[#AC3325] rounded-md text-[white]"
                        onClick={handleApplyCoupon}
                    >
                        Apply
                    </button>
                }

            </div>
        </div>
    )
}

export default Coupon

import React, { useEffect }  from "react";
import "../Components/Home/Home.css";
import { useDispatch, useSelector } from "react-redux";
import Carousal01 from "../Components/Carousal/Carousal01";
import Category from "../Components/CategorySection/Category";
import LatestEvent from "../Components/Home/LatestEvent/LatestEvent";
// import TrendingBlog from "../Components/Home/TrendingBlogs/TrendingBlog";
import { GetAllFeaturedEvents } from "../redux/actions/Admin/AdminActions";
import TrendingEvent from "../Components/Home/TrendingEvents/TrendingEvent";

const Home = () => {
  const FeaturedEventsdata = useSelector((state) => state.Admin.featuredEvents);
  const dispatch = useDispatch()
  
  useEffect(()=>{
    if(FeaturedEventsdata?.length === 0){
      dispatch(GetAllFeaturedEvents());
    }
  },[])

  return (
    <div className="!bg-color1 !overflow-x-hidden w-[100%]  !z-50  max-w-[2000px] mx-auto  2xl:px-[50px] px-[50px]  main_home">
      <Carousal01 />
      <Category />
      <TrendingEvent />
      <LatestEvent />
      {/* <TrendingBlog /> */}
    </div>
  );
};

export default Home;







import React, { useEffect, useRef, useState } from "react";
import "./styles/Index.css";
import { useDispatch, useSelector } from "react-redux";
import SidebarCategory from "./SidebarCategory";
import { useParams } from "react-router-dom";
import {
  SearchEvents,
  SearchEventsByCategory,
} from "../../../redux/actions/Events";
import { ReactSVG } from "react-svg";
import "../TicketsPage/style/Tickets.css";
import filterSVG from "../../../assets/svg/filter.svg";
import NotFoundSVG from "../../../assets/svg/NotFound.svg";
import NotFoundLodaing from "../../Loading/NotFoundLodaing";
import { data } from "../../CategorySection/DataCategory";
import FeaturedEvent from "../../EventCard/LatestCard/FeaturedEvent";
import { updateFiltrationView } from "../../../redux/actions/State";
import LatestLoading from "../../Loading/LatestLoading";




const Index = () => {
  const isLoading = useSelector((state) => state.events.isLoading);
  const serachevents = useSelector((state) => state.events.serachevents);
  const UserLocationdata = useSelector((state) => state.user.userlocation);
  const active = useSelector((state) => state.statemanagemnt.categorySidebar);

  const params = useParams();
  const { name } = params;
  const dispatch = useDispatch();
  const [categoryIdName, setCategoryIdName] = useState("");
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");
  const [page, setpage] = useState(1);

  useEffect(() => {
    if (name?.slice(0, 8) == "category") {
      data?.filter((item) => {
        const splitName = name?.split("=");
        if (splitName && item?.id == splitName[1]) {
          setCategoryIdName(item?.name);
        } else {
          item?.subCategory?.filter((item) => {
            const splitName = name?.split("=");
            if (splitName && item?.id == splitName[1]) {
              setCategoryIdName(item?.name);
            }
          })
        }

      });
      dispatch(SearchEventsByCategory(name?.split("=")[1]));
    } else {
      dispatch(SearchEvents(name, UserLocationdata));
    }
  }, [name]);


  const affiliateBanner02Ref = useRef(null)
  const depen = document?.getElementById(window.innerWidth > 678 ? "affiliateBanner03" : "affiliateBanner05")?.innerHTML;
  let affiliateBannerHtml = document?.getElementById(window.innerWidth > 678 ? "affiliateBanner03" : "affiliateBanner05")?.innerHTML
  useEffect(() => {
    affiliateBanner02Ref.current.innerHTML = affiliateBannerHtml;
    affiliateBanner02Ref.current.style.display = "block";

  }, [affiliateBanner02Ref, depen])

  return (
    <div className=" ticketcategory_index max-w-[2000px] mx-auto px-[50px] py-[20px] min-h-[100vh] !bg-color1 !text-color2 w-full">
      {/* <Header /> */}
      <div ref={affiliateBanner02Ref} className="my-[20px] w-[100%] overflow-x-auto"></div>

      <h2 className="my-3 text-[30px] underline nameforsearch">
        Search for :{" "}
        <span className="text-[33px] text-redColor font-bold">
          {name?.slice(0, 8) == "category" ? categoryIdName : name}(
          {serachevents && serachevents?.length})
        </span>
      </h2>
      <button
        className={`filter_btn bg-color4 px-5 py-2 rounded-md cursor-pointer flex justify-center place-items-center gap-2`}
        onClick={() => dispatch(updateFiltrationView(true))}
      >
        <ReactSVG src={filterSVG} />
        <p className="filter_text !text-[20px] !font-[400]">Filter</p>
      </button>
      {/* ========== main page  */}
      <div className="w-full my-[40px] min-h-[90vh] index_category_section_under_header">
        {/* ----- sidebar  */}
        <div
          className={`${active ? "categorySidebarMobile categorysidebar" : "categorysidebar"
            } w-full  min-w-[370px] h-[388px] rounded-t-[8px] overflow-hidden px-2`}
        >
          <SidebarCategory
            IsSearch={true}
            tickets={false}
            startdate={startdate}
            setstartdate={setstartdate}
            enddate={enddate}
            setenddate={setenddate}
            setpage={setpage}
            name={name}
          />
        </div>
        {/* ------ content  */}
        {isLoading ? (
          <div className="flex flex-wrap -m-4 mt-3 gap-[20px] justify-center mb-5">
          {
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => {
              return (
                <LatestLoading key={i} />
              )
            })
          }
        </div>
        ) : (
          <div className="w-full  bg-black flex justify-center place-items-start gap-5 flex-wrap">
            {/* {categoryIdName == "Theme Park" || categoryIdName == "Movies" ? ( */}
            {categoryIdName == "Theme Park" || categoryIdName == "Moiowdjqwihdowiqvies" ? (
              <div className="w-full flex justify-center place-items-start">
                <NotFoundLodaing />
              </div>
            ) : serachevents && serachevents?.length > 0 ? (
              serachevents?.map((item, index) => {
                return (
                  <FeaturedEvent
                    key={index}
                    item={item && item}
                    index={index + 1}
                  />
                );
              })
            ) : (
              <div className="flex justify-center place-items-center py-3 px-3">
                <ReactSVG src={NotFoundSVG} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;

import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteCoupon,
  UpdateCoupon,
  createCoupon,
  getAllCoupons,
} from "../../redux/actions/CouponCode";
import Spinner from "../../Components/Loading/Spinner";
import { Loading } from "notiflix";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";

const CouponCode = () => {
  const [createevent, setcreateevent] = useState(false);
  const [promoCode, setpromoCode] = useState("");
  const [discount, setdiscount] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [id, setId] = useState(null);
  const dispatch = useDispatch();
  const CouponLoading = useSelector((state) => state.couponcode.CouponLoading);
  const AllCouponCode = useSelector((state) => state.couponcode.AllCouponCode);

  //   ----- api call to get all
  useEffect(() => {
    dispatch(getAllCoupons());
  }, []);

  //   --- loading
  useEffect(() => {
    if (CouponLoading === true) {
      Loading.standard("Loading Plaese Wait");
    } else {
      Loading.remove();
    }
  }, [CouponLoading]);

  return (
    <div className="px-3">
      <div className="w-full flex justify-end place-items-end my-2">
        <button
          className="my-4 bg-color4 px-5 py-2 cursor-pointer rounded-md"
          onClick={() => setcreateevent(true)}
        >
          Add New +
        </button>
      </div>

      {/* =========================================  */}
      {createevent && (
        <div className="z-[99] fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10">
          <div className="max-h-full relative w-full max-w-xl overflow-y-auto sm:rounded-2xl bg-color4">
            <div className="w-full">
              <div className="py-16 px-5">
                <div className="">
                  <RxCross1
                    className="absolute top-4 right-4 text-[25px] cursor-pointer"
                    onClick={() => {
                      setcreateevent(false);
                      setIsUpdate(false);
                      setdiscount("");
                      setpromoCode("");
                      setId("");
                    }}
                  />

                  <div className="w-[100%] my-5">
                    <label
                      htmlFor="heading"
                      className="w-full block mb-1 pl-1 "
                    >
                      PromoCode Name
                    </label>
                    <input
                      value={promoCode}
                      onChange={(e) => setpromoCode(e.target.value)}
                      type="text"
                      placeholder="Please Enter promoCode name"
                      className="py-2 px-3 bg-color4 border-[1px] border-color5 rounded-md outline-none w-full"
                    />
                  </div>
                  <div className="w-[100%] my-5">
                    <label
                      htmlFor="heading"
                      className="w-full block mb-1 pl-1 "
                    >
                      Discount Name
                    </label>
                    <input
                      value={discount}
                      onChange={(e) => setdiscount(e.target.value)}
                      type="number"
                      placeholder="Please Enter Discount Price"
                      className="py-2 px-3 bg-color4 border-[1px] border-color5 rounded-md outline-none w-full"
                    />
                  </div>
                  {!CouponLoading ? (
                    <button
                      className="mt-2 px-3 py-3 bg-color1 text-color2 w-full text-[17px] rounded-md"
                      onClick={async () => {
                        if (isUpdate) {
                          await dispatch(UpdateCoupon(id, discount, promoCode));
                        } else {
                          await dispatch(createCoupon({ promoCode, discount }));
                        }
                        setcreateevent(false);
                        setIsUpdate(false);
                        setdiscount("");
                        setpromoCode("");
                        setId("");
                      }}
                    >
                      {isUpdate ? "update" : " Create"}
                    </button>
                  ) : (
                    <button
                      className="mt-2 px-3 py-2 bg-color1 text-color2 w-full text-[17px] rounded-md"
                      onClick={() => {
                        dispatch(createCoupon({ promoCode, discount }));
                      }}
                    >
                      {" "}
                      <Spinner />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* ====== table  */}
      <div className="w-full overflow-hidden rounded-lg shadow-xs px-[20px] my-[30px]">
        <div className="w-full overflow-x-auto rounded-lg">
          <table className="w-full ">
            <thead>
              <tr className="text-xs font-semibold rounded-t-md tracking-wide text-left text-color2 uppercase border-b border-b-color1 bg-color4">
                <th className="px-4 py-3">Id</th>
                <th className="px-4 py-3">Createdby</th>
                <th className="px-4 py-3">Promocode</th>
                <th className="px-4 py-3">Discount</th>
                <th className="px-4 py-3">Action</th>
              </tr>
            </thead>
            <tbody className="bg-color1 divide-y dark:divide-gray-700 dark:bg-gray-800">
              {AllCouponCode &&
                AllCouponCode?.map((item, index) => {
                  return (
                    <tr
                      className="bg-color3 border-b-color1  hover:bg-color1  text-color2"
                      key={index}
                    >
                      <td className="px-4 py-3 text-sm">
                        #{item?._id?.slice(0, 6)}
                      </td>

                      <td className="px-4 py-3 text-sm">
                        {item?.author && item?.author?.firstName}{" "}
                        {item?.author && item?.author?.lastName}
                      </td>
                      <td className="px-4 py-3 text-xs ">{item?.promoCode}</td>
                      <td className="px-4 py-3 text-sm">{item?.discount}</td>
                      <td className="px-4 py-3 text-sm flex justify-start place-items-center gap-2">
                        <MdDelete
                          className="text-[red] cursor-pointer text-[23px]"
                          onClick={() => {
                            dispatch(DeleteCoupon(item?._id));
                          }}
                        />
                        <FaEdit
                          className="text-[green] cursor-pointer text-[23px]"
                          accordion
                          onClick={() => {
                            setdiscount(item?.discount);
                            setpromoCode(item?.promoCode);
                            setIsUpdate(true);
                            setcreateevent(true);
                            setId(item?._id);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CouponCode;



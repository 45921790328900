import { Loading } from "notiflix";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const CustomerOrders = () => {
  const CustomerOrders = useSelector((state) => state.Admin.UserOrderById);
  const adminloading = useSelector((state) => state.Admin.adminloading);

  useEffect(() => {
    if (adminloading === true) {
      Loading.standard("Loading Plaese Wait", {
        svgColor: "#CD292E",
      });
    } else {
      Loading.remove();
    }
  }, [adminloading]);
  return (
    <div className="p-[20px]">
      <h2>Total Orders {(CustomerOrders && CustomerOrders?.length) || 0}</h2>
      {/* ============ table  */}
      <div className="mt-4  bg-col">
        <div className="w-full overflow-hidden rounded-lg shadow-xs">
          <div className="w-full overflow-x-auto">
            <table className="w-full ">
              <thead>
                <tr className="text-xs font-semibold rounded-t-md tracking-wide text-left text-color2 uppercase border-b border-b-color1 bg-color4">
                  <th className="px-4 py-3">OrderId / TicketId</th>
                  <th className="px-4 py-3">Ticket name</th>
                  <th className="px-4 py-3">Ticket price</th>
                  <th className="px-4 py-3">Ticket splits</th>
                  <th className="px-4 py-3">Ticket type</th>
                  <th className="px-4 py-3">service_fee</th>
                  <th className="px-4 py-3">shiptoEmail</th>
                  <th className="px-4 py-3">shiptoName</th>
                  <th className="px-4 py-3">status</th>
                  <th className="px-4 py-3">tax</th>
                  <th className="px-4 py-3">totalAmount</th>
                </tr>
              </thead>
              <tbody className="bg-color1 divide-y dark:divide-gray-700 dark:bg-gray-800 w-[100%]">
                {CustomerOrders?.length > 0 ? (
                  CustomerOrders?.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className="bg-color3 border-b-color1  hover:bg-color1  text-color2"
                      >
                        <td className="px-4 py-3 text-sm text-center">
                          {item?.order_Id} / {item?.item_id}
                        </td>
                        <td className="px-4 py-3">
                          <div className="flex items-center text-sm text-center">
                            <p className="text-[13px]">
                              {item && item?.items && item?.items[0]?.name}
                            </p>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-sm text-center">
                          {item && item?.items && item?.items[0]?.price}
                        </td>
                        <td className="px-4 py-3 text-sm text-center">
                          {item && item?.items && item?.items[0]?.splits}
                        </td>
                        <td className="px-4 py-3 text-xs text-center ">
                          {item && item?.type}
                        </td>
                        <td className="px-4 py-3 text-sm text-center">
                          {item && item?.service_fee}
                        </td>
                        <td className="px-4 py-3 text-sm text-center">
                          {item && item?.shiptoEmail}
                        </td>
                        <td className="px-4 py-3 text-sm text-center">
                          {item && item?.shiptoName}
                        </td>
                        <td className="px-4 py-3 text-sm text-center">
                          {item && item?.status}
                        </td>
                        <td className="px-4 py-3 text-sm text-center">
                          {item && item?.tax}
                        </td>
                        <td className="px-4 py-3 text-sm text-center">
                          {item && item?.totalAmount} $
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <p className="my-[20px] text-center w-[100%] mx-auto text-[20px]">
                    No Order found
                  </p>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerOrders;

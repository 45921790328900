import React from 'react'

const PaymentInfo = ({ PaymentInfoData, setPaymentInfoData, format }) => {

    const HnaldeInfoChnage = (e) => {
        setPaymentInfoData({ ...PaymentInfoData, [e.target.name]: e.target.value })
    }

    return (
        <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-500 dark:text-white">
                Delivery Details
            </h2>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                    <label
                        for="your_name"
                        className="mb-2 block text-sm font-medium text-[white] dark:text-white"
                    >
                        {" "}
                        First Name*{" "}
                    </label>
                    <input
                        type="text"
                        id="your_name"
                        name="fname"
                        className="block w-full rounded-lg border-none outline-none p-2.5 text-sm bg-black text-[white]"
                        placeholder="Your Name"
                        value={PaymentInfoData.fname}
                        onChange={HnaldeInfoChnage}
                        required
                        style={{
                            boxShadow: "0px 0px 5px 2px #cd292e85",
                        }}
                    />
                </div>

                <div>
                    <label
                        for="lname"
                        className="mb-2 block text-sm font-medium text-[white] dark:text-white"
                    >
                        {" "}
                        Last Name*{" "}
                    </label>
                    <input
                        type="lname"
                        id="lname"
                        className="block w-full rounded-lg border-none outline-none p-2.5 text-sm  bg-black text-[white]"
                        placeholder="Last Name"
                        name="lname"
                        value={PaymentInfoData.lname}
                        onChange={HnaldeInfoChnage}
                        required
                        style={{
                            boxShadow: "0px 0px 5px 2px #cd292e85",
                        }}
                    />
                </div>

            </div>
            <div className={`${format === "Physical" && 'grid grid-cols-1 gap-4 sm:grid-cols-2'}`}>
                <div>
                    <label
                        for="your_email"
                        className="mb-2 block text-sm font-medium text-[white] dark:text-white"
                    >
                        {" "}
                        Email*{" "}
                    </label>
                    <input
                        type="email"
                        id="your_email"
                        className={`block w-full rounded-lg border-none outline-none p-2.5 text-sm  bg-black text-[white]`}
                        placeholder="Example@gmail.com"
                        name="email"
                        value={PaymentInfoData.email}
                        onChange={HnaldeInfoChnage}
                        required
                        style={{
                            boxShadow: "0px 0px 5px 2px #cd292e85",
                        }}
                    />
                </div>
                {
                    format === "Physical" && (
                        <div>
                            <label
                                for="your_phone"
                                className="mb-2 block text-sm font-medium text-[white] dark:text-white"
                            >
                                {" "}
                                Phone Number*{" "}
                            </label>
                            <input
                                type="tel"
                                id="your_phone"
                                className="block w-full rounded-lg border-none outline-none p-2.5 text-sm  bg-black text-[white]"
                                placeholder="Your Phone Number"
                                name="phoneNumber"
                                value={PaymentInfoData.phoneNumber}
                                onChange={HnaldeInfoChnage}
                                required
                                style={{
                                    boxShadow: "0px 0px 5px 2px #cd292e85",
                                }}
                            />
                        </div>
                    )
                }
            </div>
            {
                format === "Physical" && (
                    <>
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                            <div>
                                <label
                                    for="address"
                                    className="mb-2 block text-sm font-medium text-[white] dark:text-white"
                                >
                                    {" "}
                                    Street Address*{" "}
                                </label>
                                <input
                                    type="text"
                                    id="address"
                                    className="block w-full rounded-lg border-none outline-none p-2.5 text-sm  bg-black text-[white]"
                                    placeholder="Your Address"
                                    name="address"
                                    value={PaymentInfoData.address}
                                    onChange={HnaldeInfoChnage}
                                    required
                                    style={{
                                        boxShadow: "0px 0px 5px 2px #cd292e85",
                                    }}
                                />
                            </div>
                            <div>
                                <label
                                    for="country"
                                    className="mb-2 block text-sm font-medium text-[white] dark:text-white"
                                >
                                    {" "}
                                    Country*{" "}
                                </label>
                                <input
                                    type="text"
                                    id="country"
                                    className="block w-full rounded-lg border-none outline-none p-2.5 text-sm  bg-black text-[white]"
                                    placeholder="Your country"
                                    name="country"
                                    value={PaymentInfoData.country}
                                    onChange={HnaldeInfoChnage}
                                    required
                                    style={{
                                        boxShadow: "0px 0px 5px 2px #cd292e85",
                                    }}
                                />
                            </div>
                            <div>
                                <label
                                    for="city"
                                    className="mb-2 block text-sm font-medium text-[white] dark:text-white"
                                >
                                    {" "}
                                    City*{" "}
                                </label>
                                <input
                                    type="text"
                                    id="city"
                                    className="block w-full rounded-lg border-none outline-none p-2.5 text-sm  bg-black text-[white]"
                                    placeholder="Your city"
                                    name="city"
                                    value={PaymentInfoData.city}
                                    onChange={HnaldeInfoChnage}
                                    required
                                    style={{
                                        boxShadow: "0px 0px 5px 2px #cd292e85",
                                    }}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label
                                    for="state"
                                    className="mb-2 block text-sm font-medium text-[white] dark:text-white"
                                >
                                    {" "}
                                    State*{" "}
                                </label>
                                <input
                                    type="text"
                                    id="state"
                                    className="block w-full rounded-lg border-none outline-none p-2.5 text-sm  bg-black text-[white]"
                                    placeholder="Your state"
                                    name="state"
                                    value={PaymentInfoData.state}
                                    onChange={HnaldeInfoChnage}
                                    required
                                    style={{
                                        boxShadow: "0px 0px 5px 2px #cd292e85",
                                    }}
                                />
                            </div>
                            <div>
                                <label
                                    for="zip"
                                    className="mb-2 block text-sm font-medium text-[white] dark:text-white"
                                >
                                    {" "}
                                    Postal_code*{" "}
                                </label>
                                <input
                                    type="number"
                                    id="zip"
                                    className="block w-full rounded-lg border-none outline-none p-2.5 text-sm  bg-black text-[white]"
                                    placeholder="Your zip"
                                    name="zip"
                                    value={PaymentInfoData.zip}
                                    onChange={HnaldeInfoChnage}
                                    required
                                    style={{
                                        boxShadow: "0px 0px 5px 2px #cd292e85",
                                    }}
                                />
                            </div>
                        </div>
                    </>

                )
            }
        </div>
    );
};

export default PaymentInfo

import { Loading } from "notiflix";
import toast from "react-hot-toast";
import AppleSvg from "../assets/svg/Apple.svg";
import GoogleSvg from "../assets/svg/Gpay.svg";
import PaypalSvg from "../assets/svg/paypal.svg";
import React, { useEffect, useState } from "react";
import { ApiURL } from "../setting/GlobleVariable";
import CreditSvg from "../assets/svg/Credit card.svg";
import { useDispatch, useSelector } from "react-redux";
import "../Components/PagesCompoents/GroupDeatils/Index.css";
import { CreateOrder, GetTaxData } from "../redux/actions/Events";
import Coupon from "../Components/PagesCompoents/Checkout/Coupon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ApplyCoupon, RemoveCoupon } from "../redux/actions/CouponCode";
import PaymentInfo from "../Components/PagesCompoents/Checkout/PaymentInfo";
import OrderSummery from "../Components/PagesCompoents/Checkout/OrderSummery";
import PaymentMethodsComponent from "../Components/PagesCompoents/Checkout/PaymentMethods";
import PaymentMethods from "../Components/PagesCompoents/GroupDeatils/Sidebar/PaymentMethods";

const Checkout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Location = useLocation();
  const params = useParams();
  const { filterevent, data, id } = Location.state || {};
  const user = useSelector((state) => state.user.user);
  const CouponLoading = useSelector((state) => state.couponcode.CouponLoading);
  const singleCouponCode = useSelector(
    (state) => state.couponcode.singleCouponCode
  );

  const [error, setError] = useState("");
  const [coupon, setcoupon] = useState("");
  const [payWith, setPayWith] = useState(0);
  const [PaymentInfoData, setPaymentInfoData] = useState({
    email: user?.email ? user?.email : "",
    fname: user?.firstName ? user?.firstName : "",
    lname: user?.lastName ? user?.lastName : "",
    address: user?.address ? user?.address : "",
    country: user?.country ? user?.country : "",
    city: user?.city ? user?.city : "",
    zip: user?.zip ? user?.zip : "",
    state: user?.state ? user?.state : "",
    phoneNumber: user?.phoneNumber ? user?.phoneNumber : "",
  });
  const isPaymentInfoDataValue =
    data?.format === "Physical"
      ? PaymentInfoData.fname &&
        PaymentInfoData.lname &&
        PaymentInfoData.email &&
        PaymentInfoData.address &&
        PaymentInfoData.country &&
        PaymentInfoData.city &&
        PaymentInfoData.zip &&
        PaymentInfoData.phoneNumber &&
        PaymentInfoData.state
      : PaymentInfoData.fname && PaymentInfoData.lname && PaymentInfoData.email;
  const isloadingcreateOrder = useSelector((state) => state.events.isLoading);
  const isloadingUserOrder = useSelector((state) => state.order.isloading);
  const PaymentDetails = useSelector((state) => state.events.TaxData);

  //   // handleRemoveCoupon
  const handleRemoveCoupon = () => {
    dispatch(RemoveCoupon());
    setcoupon("");
  };

  //   /// handleApplyCoupon
  const handleApplyCoupon = async () => {
    if (!coupon) {
      toast.error("Coupon field is required");
      return;
    }
    await dispatch(ApplyCoupon(coupon));
  };

  // handle place order
  const placeOrder = async (payemtResult) => {
    setError("")
    const offline = user?.email ? false : true;
    const paymentData = {
      payemtResult: payemtResult,
      wholesale_price: data?.wholesale_price,
      service_fee: PaymentDetails?.wholesale?.service_fee,
      tax: PaymentDetails?.wholesale?.tax,
      phoneNumber: user?.email
        ? user?.phoneNumber
        : PaymentInfoData?.phoneNumber,
      address: {
        address: PaymentInfoData?.address,
        country: PaymentInfoData?.country,
        city: PaymentInfoData?.city,
        zip: PaymentInfoData?.zip,
        state: PaymentInfoData?.state,
      },
    };

    dispatch(
      await CreateOrder(
        data && data?.id,
        data && data.qty,
        data && data?.retail_price,
        data && data?.format,
        id,
        user?.email ? user?.clientId : sessionStorage.getItem("client_id"),
        user?.email
          ? user?.emailAddressId
          : sessionStorage.getItem("emailAddressId"),
        dispatch,
        navigate,
        offline,
        PaymentInfoData.fname,
        PaymentInfoData.email,
        PaymentInfoData.lname,
        PaymentDetails?.tax_signature,
        paymentData,
        setError
      )
    );
  };

  // when payment is complete
  const onPaymentCompleted = async (payemtResult) => {
    setPayWith(0);
    await placeOrder(payemtResult);
    await dispatch(RemoveCoupon());
  };

  useEffect(() => {
    if (isloadingcreateOrder || isloadingUserOrder) {
      Loading.standard("Loading Plaese Wait", {
        svgColor: "#CD292E",
      });
    } else {
      Loading.remove();
    }
  }, [isloadingcreateOrder, isloadingUserOrder]);

  useEffect(() => {
    setError("")
    dispatch(
      GetTaxData(
        {
          ticket_group_id: data?.id,
          quantity: data?.qty,
          price: data?.retail_price,
        },
        setError
      )
    );
  }, [data, dispatch]);

  useEffect(() => {
    setPaymentInfoData({
      email: user?.email ? user?.email : "",
      fname: user?.firstName ? user?.firstName : "",
      lname: user?.lastName ? user?.lastName : "",
      phoneNumber: user?.phoneNumber ? user?.phoneNumber : "",
    });
  }, [user]);

  const createTevoClient = async () => {
    if (!user?.email || !user?.clientId) {
      // create tevo client Api call
      const res = await fetch(`${ApiURL}/user/tevo/client`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: PaymentInfoData?.email,
          firstName: PaymentInfoData?.fname,
          lastName: PaymentInfoData?.lname,
        }),
      });
      const tevoClientData = await res.json();
      if (res.status === 400 || res.status === 500) {
        setError("Error Occured while creating Tevo Client");
        return toast.error("Error Occured while creating Tevo Client");
      }

      // save in session storage
      sessionStorage.setItem("client_id", tevoClientData?.client_id);
      sessionStorage.setItem("emailAddressId", tevoClientData?.emailAddressId);

      // return {
      //   client_id: tevoClientData?.client_id,
      //   emailAddressId: tevoClientData?.emailAddressId,
      // };

      return tevoClientData?.client_id;
    }
  };


  return (
    <>
      <section className=" md:py-5 antialiased my-[50px]  bg-black">
        <div className="mx-auto max-w-screen-xl px-4 2xl:px-0  ">
          <h3 className="text-[35px] font-semibold text-[white] dark:text-white md:mb-[50px] mb-[15px] flex gap-3 place-items-center">
            Payment Information
          </h3>
          {error && (
            <div role="alert" className="alert alert-error">
              <span>Error : {error || "Something went wrong"}</span>
            </div>
          )}
          <div className="mt-6 sm:mt-8 lg:flex lg:items-start lg:gap-12 xl:gap-16">
            <div className="min-w-0 flex-1 space-y-8">
              <div className="space-y-4">
                <h3 className="md:text-[2vw] text-[4vw]  font-semibold text-[white] dark:text-white">
                  {(filterevent && filterevent[0]?.name) || params?.name}
                </h3>
                <PaymentInfo
                  PaymentInfoData={PaymentInfoData}
                  setPaymentInfoData={setPaymentInfoData}
                  format={data?.format}
                />
                <h2 className="text-2xl font-semibold text-gray-500 dark:text-white !my-5">
                  Payment Method
                </h2>

                <Coupon
                  CouponLoading={CouponLoading}
                  coupon={coupon}
                  handleApplyCoupon={handleApplyCoupon}
                  handleRemoveCoupon={handleRemoveCoupon}
                  setcoupon={setcoupon}
                />
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                  <PaymentMethods
                    svg={CreditSvg}
                    title={"Credit Card"}
                    payWith={1}
                    isPaymentInfoDataValue={isPaymentInfoDataValue}
                    setPayWith={setPayWith}
                  />
                  <PaymentMethods
                    svg={PaypalSvg}
                    title={"Paypal"}
                    payWith={2}
                    isPaymentInfoDataValue={isPaymentInfoDataValue}
                    setPayWith={setPayWith}
                  />
                  <PaymentMethods
                    svg={AppleSvg}
                    title={"Apple Pay"}
                    payWith={3}
                    isPaymentInfoDataValue={isPaymentInfoDataValue}
                    setPayWith={setPayWith}
                  />
                  <PaymentMethods
                    svg={GoogleSvg}
                    title={"Google Pay"}
                    payWith={4}
                    isPaymentInfoDataValue={isPaymentInfoDataValue}
                    setPayWith={setPayWith}
                  />
                </div>
              </div>
            </div>

            <OrderSummery
              PaymentDetails={PaymentDetails}
              data={data}
              singleCouponCode={singleCouponCode}
              event={filterevent}
            />
          </div>
        </div>
      </section>
      <PaymentMethodsComponent
        PaymentDetails={PaymentDetails}
        createTevoClient={createTevoClient}
        data={data}
        onPaymentCompleted={onPaymentCompleted}
        payWith={payWith}
        setPayWith={setPayWith}
      />
    </>
  );
};

export default Checkout;

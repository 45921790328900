import React, { useMemo } from 'react'
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { SwiperBreakPoint } from '../../staticData/StaticData';
const TrendingSkeleton = () => {
    const breakPointsValues = useMemo(() => SwiperBreakPoint, []);
    return (
        <Swiper
            slidesPerView={4}
            spaceBetween={0}
            freeMode={true}
            breakpoints={breakPointsValues}
            className="mySwiper my-2 mx-auto 2xl:gap-[30px] pt-1 pb-3"
            lazyPreloadPrevNext={true}
        >
            {[1,0, 2, 3, 4, 5, 6].map((item) => <SwiperSlide key={Math.random()}>
                <div className="!w-[100%] h-[300px] relative rounded-lg  animate-pulse">
                    <div className="h-[100%] w-[100%] bg-gray-200 rounded-md"></div>
                    <div className="h-[100px] w-[100%] bg-gray-300 rounded-md absolute bottom-0 left-0 z-10 flex justify-end place-items-center flex-col py-3 px-2">
                        <div className="w-[150px] h-[40px] bg-gray-400 rounded-md z-20"></div>
                        {/* <div className="w-[100%] bg-gray-400 rounded-md"></div> */}
                    </div>
                </div>
            </SwiperSlide>)
            }
        </Swiper>

    )
}

export default TrendingSkeleton

import "../Login.css";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import LoginAuth from "../../PrivateRoutes/LoginAuth";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { ForgotPassword, ResetPassword, } from "../../../redux/actions/User";

const ForgotPassCompo = () => {
  const isLoading = useSelector((state) => state.user.isLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setemail] = useState("");
  const [OTP, setOTP] = useState("");
  const [IsOTPVerify, setIsOTPVerify] = useState(false);
  const [password, setpassword] = useState("");


  const dataSubmit = (e) => {
    e.preventDefault();
    if (!IsOTPVerify) {
      if (!email) {
        return toast.error("Please enter your email");
      }
      dispatch(ForgotPassword(email, setIsOTPVerify));
    } else {
      if (!OTP) {
        return toast.error("Please enter your OTP");
      }
      if (!password) {
        return toast.error("Please enter your password");
      }
      dispatch(ResetPassword(OTP, password, navigate));
    }
  };

  useEffect(() => {
    if (isLoading === true) {
      Loading.standard("Loading Plaese Wait", {
        svgColor: "#CD292E",
      });
    } else {
      Loading.remove();
    }
  }, [isLoading]);

  return (
    <LoginAuth>
      <div className="bg-color1 my-2 w-full flex justify-center place-items-center py-10 min-h-[100vh]">
        <div className="login-box">
          <h2 className="">Forgot Password</h2>
          <form onSubmit={dataSubmit}>
            {!IsOTPVerify && (
              <div className="user-box">
                <input
                  type="email"
                  name=""
                  required=""
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  placeholder="Enter your email address"
                  className="mt-3"
                />
              </div>
            )}
            {IsOTPVerify && (
              <>
                <div className="user-box">
                  <input
                    type="number"
                    name=""
                    required=""
                    value={OTP}
                    onChange={(e) => setOTP(e.target.value)}
                    placeholder="Enter your OTP"
                    className="mt-3"
                  />
                  <label className="!text-[18px] !text-color5">Enter OTP</label>
                </div>
                <div className="user-box my-3">
                  <input
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                    type="password"
                    name=""
                    required=""
                    placeholder="Enter your password"
                    className="mt-3"
                  />
                  <label className="!text-[18px] mb-2 !text-color5">
                    Password
                  </label>
                </div>
              </>
            )}
            <button
              type="submit"
              className="w-full border-[1px] border-color2 text-color2 py-3 rounded-md my-3 px-2 cursor-pointer
        hover:bg-color4 hover:border-color4
        "
            >
              {IsOTPVerify ? "Update Password" : "Get OTP"}
            </button>
          </form>
        </div>
      </div>
    </LoginAuth>
  );
};

export default ForgotPassCompo;

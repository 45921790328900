import React, { useState, useEffect } from "react";
import dropin from "braintree-web-drop-in";
import { ApiURL } from "../../setting/GlobleVariable";
import toast from "react-hot-toast";
import { Loading } from "notiflix";
import Spinner from "../Loading/Spinner";
import { useSelector } from "react-redux";

const PayPalPayment = ({ show, onPaymentCompleted, totalamount, setShow, createTevoClient }) => {
  const [braintreeInstance, setBraintreeInstance] = useState(null);
  const [isloading, setIsloading] = useState(false);
  const [loading, setloading] = useState(false);
  const isloadingcreateOrder = useSelector((state) => state.events.isLoading);
  const user = useSelector((state) => state.user.user);



  const singleCouponCode = useSelector(
    (state) => state.couponcode.singleCouponCode
  );

  useEffect(() => {
    if (isloading === true || isloadingcreateOrder === true) {
      Loading.standard("Loading Plaese Wait");
    } else {
      Loading.remove();
    }
  }, [isloading, isloadingcreateOrder]);


  useEffect(() => {
    const initializeBraintree = async () => {
      if (show === true) {
        try {
          setloading(true);
          if (!user?.clientId) {
            var clientId;
            clientId = sessionStorage.getItem("client_id");
            if (!clientId) {
             clientId = await createTevoClient();
            }
          }
          const response = await fetch(`${ApiURL}/payment/generateClient/${user?.clientId || clientId}`, {
            method: "GET",
            headers: {
              token: localStorage.getItem("instapass_user_token"),
            },
          });
          const data = await response.json();
          if (response.status === 400 || response.status === 500) {
            return toast.error(data.message);
          }

          const { clientToken } = data;

          var instance = await dropin.create({
            authorization: clientToken,
            container: "#braintree-drop-in-div",
            card: false,
            paypal: {
              flow: "checkout",
              amount:
                singleCouponCode && singleCouponCode?.discount
                  ? totalamount - singleCouponCode?.discount <= 0
                    ? 0
                    : totalamount - singleCouponCode?.discount
                  : totalamount,
              currency: "USD",
              enableShippingAddress: false,
              enableBillingAddress: false,
              shippingAddressEditable: false,
              payeeEmail: "your_merchant_account_email@example.com",
              displayName: "Your Merchant Name",
              buttonStyle: {
                color: "gold",
                shape: "rect",
                size: "medium",
              },
            },
          });
          setloading(false);
          setBraintreeInstance(instance);

          instance.on("paymentMethodRequestable", (event) => {
            instance.requestPaymentMethod((err, payload) => {
              if (err) {
                console.error(err, "error from payap");
                // toast.error(err.message || "Something went wrong")

                return;
              }
              const nonce = payload.nonce;
              handlePayment(nonce);
            });
          });
        } catch (error) {
          console.error("Error initializing Braintree:", error);
          toast.error(error.message || "Something went wrong")

        }
      }
    };

    if (show === true) {
      initializeBraintree();
    } else {
      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => setBraintreeInstance(null));
      }
    }

    return () => {
      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => setBraintreeInstance(null));
      }
    };
  }, [show]);

  const handlePayment = async (nonce) => {
    onPaymentCompleted({
      payment_method_nonce: nonce,
      paymentType: "PayPal",
      paymentMethod: 'BraintreePayPal'
    });
  };

  return (
    <div style={{ display: `${show ? "block" : "none"}` }}>
      <div id="braintree-drop-in-div" />
      {loading && (
        <div className="w-full flex justify-center place-items-center py-2 px-2">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default PayPalPayment;

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Loading } from "notiflix";
import { RxCross2 } from "react-icons/rx";
import { useParams } from "react-router-dom";
import AppleSvg from "../assets/svg/Apple.svg";
import GoogleSvg from "../assets/svg/Gpay.svg";
import PaypalSvg from "../assets/svg/paypal.svg";
import Spinner from "../Components/Loading/Spinner";
import { IoIosArrowRoundBack } from "react-icons/io";
import CreditSvg from "../assets/svg/Credit card.svg";
import { useDispatch, useSelector } from "react-redux";
import PaymentMethods from "../Components/PagesCompoents/GroupDeatils/Sidebar/PaymentMethods";
import { GetTaxData } from "../redux/actions/Events";



const NewPaymentPage = ({
  filterevent,
  receivedData,
  setPayWith,
  getOrderData,
  setIsPayment,
  data,
  singleCouponCode,
  setcoupon,
  coupon,
  RemoveCoupon,
  ApplyCoupon,
  CouponLoading,
  PaymentInfoData, setPaymentInfoData
}) => {
  const [active, setActive] = useState(1);

  const isPaymentInfoDataValue = PaymentInfoData.fname && PaymentInfoData.lname && PaymentInfoData.email  ? true : false;
  const isloadingcreateOrder = useSelector((state) => state.events.isLoading);
  const isloadingUserOrder = useSelector((state) => state.order.isloading)
  const PaymentDetails = useSelector((state) => state.events.TaxData);
  
  
  const params = useParams();
  const dispatch = useDispatch();



  // handleRemoveCoupon
  const handleRemoveCoupon = () => {
    dispatch(RemoveCoupon());
    setcoupon("");
  }

  /// handleApplyCoupon
  const handleApplyCoupon = async () => {
    if (!coupon) {
      toast.error("Coupon field is required");
      return;
    }
    await dispatch(ApplyCoupon(coupon));
  }


  
  useEffect(() => {
    if (isloadingcreateOrder || isloadingUserOrder) {
      Loading.standard("Loading Plaese Wait");
    }
    else {
      Loading.remove();
    }

  }, [isloadingcreateOrder, isloadingUserOrder])

  useEffect(()=>{
      dispatch(GetTaxData({
        ticket_group_id : data?.id,
        quantity : data?.qty,
        price : data?.retail_price
      }))
  },[data,dispatch])



  return (
    <>
      <section className=" md:py-5 antialiased my-[50px]  bg-black">
        <div className="mx-auto max-w-screen-xl px-4 2xl:px-0  ">
          <h3 className="text-[35px] font-semibold text-[white] dark:text-white md:mb-[50px] mb-[15px] flex gap-3 place-items-center">
            <IoIosArrowRoundBack className="text-[50px] cursor-pointer" onClick={() => setIsPayment(false)} /> Payment
          </h3>

          <div className="mt-6 sm:mt-8 lg:flex lg:items-start lg:gap-12 xl:gap-16">
            <div className="min-w-0 flex-1 space-y-8">
              <div className="space-y-4">
                <h3 className="md:text-[2vw] text-[4vw]  font-semibold text-[white] dark:text-white">
                  {(filterevent && filterevent[0]?.name) ||
                    (receivedData && receivedData[0]?.name) || params?.name}
                </h3>


                {active === 1 && <PaymentInfo
                  PaymentInfoData={PaymentInfoData}
                  setPaymentInfoData={setPaymentInfoData}
                  setActive={setActive}
                  active={active}
                />}

                <h2 className="text-2xl font-semibold text-gray-500 dark:text-white !my-5">
                  Payment Method
                </h2>

                {active === 1 && (
                  <div>
                    <label
                      for="voucher"
                      className="mb-2 block text-sm font-medium text-[white] dark:text-white"
                    >
                      {" "}
                      Enter a promotional code{" "}
                    </label>
                    <div className="max-w-[100%] flex gap-2 mb-5 !text-[white]">
                      <div className="w-[100%] relative">
                        <input
                          type="text"
                          placeholder="enter promo code"
                          className="w-[100%] py-2 px-3 rounded-md outline-none border-none bg-black"
                          style={{
                            boxShadow: "0px 0px 5px 2px #cd292e85",
                          }}
                          value={coupon}
                          onChange={(e) => setcoupon(e.target.value)}
                        />
                        <RxCross2 className="absolute right-3 top-[10px] z-10 cursor-pointer text-[22px]"
                          onClick={handleRemoveCoupon}
                        />
                      </div>
                      {CouponLoading ?
                        <button className="w-[150px] bg-[#ac332580] rounded-md text-[white] py-1 px-4 cursor-not-allowed">
                          <Spinner />
                        </button>

                        :
                        <button className="w-[150px] bg-[#AC3325] rounded-md text-[white]"
                          onClick={handleApplyCoupon}
                        >
                          Apply
                        </button>
                      }

                    </div>
                  </div>
                )}


                {active === 1 && (
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <PaymentMethods svg={CreditSvg} title={"Credit Card"} payWith={1} data={data} getOrderData={getOrderData} setPayWith={setPayWith} isPaymentInfoDataValue={isPaymentInfoDataValue} />
                    <PaymentMethods svg={PaypalSvg} title={"Paypal"} payWith={2} data={data} getOrderData={getOrderData} setPayWith={setPayWith} isPaymentInfoDataValue={isPaymentInfoDataValue} />
                    <PaymentMethods svg={AppleSvg} title={"Apple Pay"} payWith={3} data={data} getOrderData={getOrderData} setPayWith={setPayWith} isPaymentInfoDataValue={isPaymentInfoDataValue} />
                    <PaymentMethods svg={GoogleSvg} title={"Google Pay"} payWith={4} data={data} getOrderData={getOrderData} setPayWith={setPayWith} isPaymentInfoDataValue={isPaymentInfoDataValue} />
                  </div>
                )}
              </div>
            </div>

            {
              active >= 1 &&
              <div className="mt-6 w-full space-y-6 sm:mt-8 lg:mt-0 lg:max-w-xs xl:max-w-md !sticky !top-[50px]">
                <h2 className="text-xl font-semibold text-gray-500 dark:text-white">
                  Order Summery
                </h2>
                <div className="flow-root">
                  <div className="-my-3 divide-y divide-gray-200 dark:divide-gray-800">
                    <dl className="flex items-center justify-between gap-4 py-3">
                      <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                        Price/seat
                      </dt>
                      <dd className="text-base font-medium text-[white] dark:text-white">
                        ${data?.retail_price}
                      </dd>
                    </dl>
                    <dl className="flex items-center justify-between gap-4 py-3">
                      <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                        Quantity
                      </dt>
                      <dd className="text-base font-medium text-[white] dark:text-white">
                        {data?.qty}
                      </dd>
                    </dl>

                    <dl className="flex items-center justify-between gap-4 py-3">
                      <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                        Service fee
                      </dt>
                      <dd className="text-base font-medium text-green-500">
                        {data?.service_fee}
                      </dd>
                    </dl>

                    <dl className="flex items-center justify-between gap-4 py-3">
                      <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                        Discount
                      </dt>
                      <dd className="text-base font-medium text-green-500">${singleCouponCode && singleCouponCode?.discount
                        ? singleCouponCode?.discount
                        : "0"}</dd>
                    </dl>

                    <dl className="flex items-center justify-between gap-4 py-3">
                      <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                        Tax
                      </dt>
                      <dd className="text-base font-medium text-[white] dark:text-white">
                        ${PaymentDetails?.retail?.tax}
                      </dd>
                    </dl>
                    <dl className="flex items-center justify-between gap-4 py-3">
                      <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                        Subtotal
                      </dt>
                      <dd className="text-base font-medium text-[white] dark:text-white">
                        $ {data?.retail_price} X {data?.qty} ={" "}
                        {(data?.retail_price * data?.qty).toFixed(2)}
                      </dd>
                    </dl>
                    <dl className="flex items-center justify-between gap-4 py-3">
                      <dt className="text-base font-bold text-gray-500 dark:text-white">
                        Total
                      </dt>
                      <dd className="text-base font-bold text-[white] dark:text-white">
                        $ {singleCouponCode && singleCouponCode?.discount
                          ? data?.retail_price * data?.qty + PaymentDetails?.retail?.tax +
                            data?.service_fee -
                            singleCouponCode?.discount <=
                            0
                            ? 0
                            : (data?.retail_price * data?.qty + PaymentDetails?.retail?.tax +
                            data?.service_fee -
                            singleCouponCode?.discount).toFixed(2)
                          : (data?.retail_price * data?.qty + data?.service_fee + PaymentDetails?.retail?.tax).toFixed(2)}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            }
          </div>

        </div>


      </section>
    </>
  );
};

export default NewPaymentPage;

const PaymentInfo = ({ PaymentInfoData, setPaymentInfoData, setActive, active }) => {

  const HnaldeInfoChnage = (e) => {
    setPaymentInfoData({ ...PaymentInfoData, [e.target.name]: e.target.value })
  }

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold text-gray-500 dark:text-white">
        Delivery Details
      </h2>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <div>
          <label
            for="your_name"
            className="mb-2 block text-sm font-medium text-[white] dark:text-white"
          >
            {" "}
           First Name*{" "}
          </label>
          <input
            type="text"
            id="your_name"
            name="fname"
            className="block w-full rounded-lg border-none outline-none p-2.5 text-sm bg-black text-[white]"
            placeholder="Your Name"
            value={PaymentInfoData.fname}
            onChange={HnaldeInfoChnage}
            required
            style={{
              boxShadow: "0px 0px 5px 2px #cd292e85",
            }}
          />
        </div>

        <div>
          <label
            for="lname"
            className="mb-2 block text-sm font-medium text-[white] dark:text-white"
          >
            {" "}
            Last Name*{" "}
          </label>
          <input
            type="lname"
            id="lname"
            className="block w-full rounded-lg border-none outline-none p-2.5 text-sm  bg-black text-[white]"
            placeholder="Last Name"
            name="lname"
            value={PaymentInfoData.lname}
            onChange={HnaldeInfoChnage}
            required
            style={{
              boxShadow: "0px 0px 5px 2px #cd292e85",
            }}
          />
        </div>
        
      </div>
      <div>
          <label
            for="your_email"
            className="mb-2 block text-sm font-medium text-[white] dark:text-white"
          >
            {" "}
            Email*{" "}
          </label>
          <input
            type="email"
            id="your_email"
            className="block w-full rounded-lg border-none outline-none p-2.5 text-sm  bg-black text-[white]"
            placeholder="Example@gmail.com"
            name="email"
            value={PaymentInfoData.email}
            onChange={HnaldeInfoChnage}
            required
            style={{
              boxShadow: "0px 0px 5px 2px #cd292e85",
            }}
          />
        </div>
    </div>
  );
};


import React, { useCallback, useEffect, useState } from "react";
import "./map.css";
import moment from "moment-timezone";
import { ApiURL } from "../../../setting/GlobleVariable";
import toast from "react-hot-toast";
import $ from "jquery";

const Seatics = ({ setSidebarData, GroupsEvents, event, SeaticsData }) => {
  const [isLoading, setisLoading] = useState(true);
  const [error, setError] = useState(false);
  var demoTicketData = [];

  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject(console.log(`Error loading ${src}`));
      document.body.appendChild(script);
    });
  };

  const initializeSeatics = useCallback(async () => {
    setisLoading(true);
    setSidebarData([]);
    //load seatics
    if (!window.Seatics) {
      await loadScript("https://mapwidget3.seatics.com/Api/framework");
    }
    //load jquery
    if (!window.$) {
      await loadScript("https://code.jquery.com/jquery-3.6.0.min.js");
    }
    if (event?.available_count === 0) {
      setisLoading(false);
    }
    if (GroupsEvents?.length === 0) return;
    if (SeaticsData?.length !== 0) return;
    const Consumer_Key = process.env.REACT_APP_CONSUMER_KEY;
    const websiteConfigID = process.env.REACT_APP_WEBCONFIG_ID;
    const eventName = event?.name;
    const venueName = event?.venue?.name;
    const date = moment.utc(event?.occurs_at);
    const formattedDate = date.format("YYYYMMDDHHmm");
    const script = document.createElement("script");
    const response = await fetch(`${ApiURL}/seatics/script/`);
    const result = await response.json();
    script.src = `https://www.tn-apis.com/maps/v3/EventAndVenueInfo?callback=callback&websiteConfigId=${websiteConfigID}&consumerKey=${Consumer_Key}&access_token=${result?.token}&eventName=${eventName}&venue=${venueName}&dateTime=${formattedDate}`;
    document.body.appendChild(script);
    const container = $("#mapContainer");

    window.callback = async (data) => {
      if (data[0] === null) {
        setisLoading(false);
        setError(true);
        return toast.error("No Data Found");
      }
      var eventData = data[0];
      const mapData = data[1];
      await window.Seatics.MapComponent.create({
        container: container,
        mapWidth: 700,
        mapHeight: window?.innerWidth < 600 ? 400 : 600,
        mapData: mapData,
        imgSrc: eventData.mapImage,
        mapName: eventData.mapName,
        vfsUrl: "https://vfs.Seatics.com",
        tickets: [],
        presentationInterface: {
          updateTicketsList: myCreateListFunction,
        },
        enableSectionInfoPopups: true,
        onSectionClick: true,
        ticketList: true,
      });
    };

    await GroupsEvents?.forEach((item) => {
      demoTicketData.push({
        tgUserSec: item?.section || "",
        tgUserRow: item?.row || "",
        tgUserSeats: item?.seat_numbers || "",
        tgQty: item?.available_quantity || 0,
        tgPrice: item?.retail_price || 0,
        tgID: item?.id || null,
        tgNotes: item?.public_notes || "",
        tgMark: item?.featured ? 1 : 0,
        tgType: 1,
        tgDeliveryOptions: item?.eticket ? "EM" : "",
        tgFaceValue: item?.face_value || null,
        tgClientData: item?.tevo_section_name || "",
        tgCType: item?.format,
        tgDisclaimers: [],
        tgServiceFee: item?.service_fee || 0,
        tgSplitRuleId: item?.splits || [],
      });
    });

    const myCreateListFunction = async (Data) => {
      var ticketsData = Data[0].tickets;
      setSidebarData(ticketsData);
    };

    window.Seatics.config.largeScreenFormat = true;
    window.Seatics.config.hoverEnabled = true;
    setTimeout(function () {
      window.Seatics?.MapComponent?.addTicketData &&
        window.Seatics?.MapComponent?.addTicketData(demoTicketData);
      setisLoading(false);
    }, 5000);

    //COLOR SCHEME SETTINGS
    window.Seatics.config.levelColors = [
      "#d86f6f",
      "#e2a6a6",
      "#f4ad58",
      "#ffd797",
      "#bc91c1",
      "#e5cae2",
      "#66b6dd",
      "#a3ddf8",
      "#abcb59",
      "#d2e08b",
      "#bfa082",
      "#d9d7ba",
      "#589caf",
      "#bcd3e7",
      "#fff871",
      "#fff7a9",
      "#9bc9a2",
      "#c7ddc8",
    ];
    window.Seatics.config.zoneColors = [
      "#d86f6f",
      "#e2a6a6",
      "#f4ad58",
      "#ffd797",
      "#bc91c1",
      "#e5cae2",
      "#66b6dd",
      "#a3ddf8",
      "#abcb59",
      "#d2e08b",
      "#bfa082",
      "#d9d7ba",
      "#589caf",
      "#bcd3e7",
      "#fff871",
      "#fff7a9",
      "#9bc9a2",
      "#c7ddc8",
    ];
    window.Seatics.config.levelColors = [
      "#d86f6f",
      "#e2a6a6",
      "#f4ad58",
      "#ffd797",
      "#bc91c1",
      "#e5cae2",
      "#66b6dd",
      "#a3ddf8",
      "#abcb59",
      "#d2e08b",
      "#bfa082",
      "#d9d7ba",
      "#589caf",
      "#bcd3e7",
      "#fff871",
      "#fff7a9",
      "#9bc9a2",
      "#c7ddc8",
    ]; //for single color maps
    window.Seatics.config.useSuperLevels = true; //false for full palette, true for simplified palette
    window.Seatics.config.sectionNoTicketsColor = "#e2e2e2";

    //SELECTION SCHEME SETTINGS
    window.Seatics.config.selectionScheme = 0; // options: 0, 1
    window.Seatics.config.selectionColor = "#ffff00";
    window.Seatics.config.selectionPulseFromColor = null;

    //UNMAPPED INVENTORY SETTINGS
    window.Seatics.config.ticketSeparationOptions = {
      packages: window.Seatics.TicketGroupSeparationOptions.Integrate,
      parking: window.Seatics.TicketGroupSeparationOptions.Separate,
      passes: window.Seatics.TicketGroupSeparationOptions.Separate,
      hotels: window.Seatics.TicketGroupSeparationOptions.Separate,
      unmappedFlex: window.Seatics.TicketGroupSeparationOptions.Integrate,
      unmappedStandard: window.Seatics.TicketGroupSeparationOptions.Separate,
    };

    //DEFAULT SORT
    window.Seatics.config.defaultSort = window.Seatics.SortOptions.PriceAsc; //options: PriceAsc, PriceDesc, SectionAsc, SectionDesc, Marked

    //STICKY FILTERS
    window.Seatics.config.saveFilterOptions = true;

    //MY LIST FEATURE
    window.Seatics.config.enableMyList = false;

    //MOBILE MAP SETTINGS
    window.Seatics.config.smallScreenMapLayout =
      window.Seatics.SmallScreenMapOptions.HiddenWithPreview; //options: FullyHidden, HalfShown, HiddenWithPreview

    //ZOOM CONTROLS ON MOBILE
    window.Seatics.config.showZoomControls = true;

    //SINGLE CLICK TO CHECKOUT
    window.Seatics.config.skipPrecheckoutMobile = false;
    window.Seatics.config.skipPrecheckoutDesktop = false;

    //DESKTOP LAYOUT SETTINGS
    window.Seatics.config.ticketListOnRight = false;
    window.Seatics.config.mapContained = false;
    window.Seatics.config.legendExpanded = false;

    //Determines whether to show cents in prices
    window.Seatics.config.showCents = false;

    // sidebar
    window.Seatics.config.ticketListOnRight = true;
    //   }
    // }
  }, [GroupsEvents]);

  useEffect(() => {
    initializeSeatics();
  }, [initializeSeatics]);

  return (
    <div className="w-[100%] h-[100%] bg-white relative">
      <div
        id="mapContainer"
        className="w-[100%] h-[400px] sm:h-[600px]  2xl:h-[100%]"
      ></div>
      {isLoading && (
        <div className="w-[100%] h-[100%] absolute top-0 left-0 z-40 bg-white flex justify-center place-items-center">
          <div
            className="inline-block h-[60px] w-[60px] animate-spin rounded-full border-4 border-solid border-current border-r-transparent text-black align-[-0.125em] motion-reduce:animate-[spin_.5s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>
      )}

      {event?.available_count === 0 && (
        <div className="absolute top-0 left-0 w-[100%] h-[100%] bg-white z-50 flex justify-center place-items-center">
          <h2 className="text-black">No data found</h2>
        </div>
      )}
      {(error) && (
        <div className="absolute top-0 left-0 w-[100%] h-[100%] bg-white z-50 flex justify-center place-items-center">
          <h2 className="text-black">Something went wrong. No data found</h2>
        </div>
      )}
    </div>
  );
};

export default React.memo(Seatics);
